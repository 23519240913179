/* Tabellen */
table tbody tr:first-child td,
th {
  background: transparent;
  border-bottom: 1px solid #b6c0d5;
  border-left: 1px solid #b6c0d5;
  border-top: none;
  border-right: none;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  line-height: 24px;
  padding: 5px 6px;
  border-left: 1px solid #b6c0d5;
  hyphens: auto;
  font-family: "bliss-pro-light";
}

td {
  border-top: 1px solid #b6c0d5;
}

/* td:nth-child(odd) {
	background-color: #e9ecf3;
} */
tbody > tr:first-child {
  background-color: #e9e9e9;
}
table tbody tr:first-child td,
th {
  font-weight: bold;
  background: transparent;
}

td,
tr,
th,
tbody,
table {
  font-size: 16px !important;
  font-weight: normal;
}

tr li,
td p,
td li,
td span {
  font-size: 16px !important;
  font-weight: normal;
}

td ul {
  padding-left: 20px;
}

td strong {
  font-size: 16px !important;
  font-weight: bold;
}

.high {
  transform: translateY(-8px);
  vertical-align: super;
  font-size: 0.8em;
}

/* Targeting common text-containing HTML elements */
body,
div,  
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
span,
ul,
ol,
td,
th,
blockquote,
pre,
code {
  user-select: text !important;
}
